var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CContainer",
    { staticClass: "min-vh-100 d-flex align-items-center" },
    [
      _c(
        "CRow",
        { staticClass: "w-100 justify-content-center" },
        [
          _c(
            "CCol",
            { attrs: { md: "6", sm: "8" } },
            [
              _c(
                "CCard",
                { staticClass: "mx-4 mb-0" },
                [
                  _c(
                    "CCardBody",
                    { staticClass: "p-4" },
                    [
                      _c(
                        "CForm",
                        [
                          _c("h1", [_vm._v("Register")]),
                          _c("p", { staticClass: "text-muted" }, [
                            _vm._v("Create your account")
                          ]),
                          _c("CInput", {
                            attrs: {
                              placeholder: "Username",
                              type: "text",
                              autocomplete: "username"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "prepend-content",
                                fn: function() {
                                  return [
                                    _c("CIcon", { attrs: { name: "cil-user" } })
                                  ]
                                },
                                proxy: true
                              }
                            ]),
                            model: {
                              value: _vm.username,
                              callback: function($$v) {
                                _vm.username = $$v
                              },
                              expression: "username"
                            }
                          }),
                          _c("CInput", {
                            attrs: { placeholder: "Email", type: "text" },
                            model: {
                              value: _vm.email,
                              callback: function($$v) {
                                _vm.email = $$v
                              },
                              expression: "email"
                            }
                          }),
                          _c("CInput", {
                            attrs: {
                              placeholder: "Password",
                              type: "password",
                              autocomplete: "new-password"
                            },
                            model: {
                              value: _vm.password,
                              callback: function($$v) {
                                _vm.password = $$v
                              },
                              expression: "password"
                            }
                          }),
                          _c(
                            "CButton",
                            {
                              attrs: { color: "success", block: "" },
                              on: { click: _vm.signUp }
                            },
                            [_vm._v("Create Account")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "CCardFooter",
                    { staticClass: "p-4" },
                    [
                      _c(
                        "CRow",
                        [
                          _c(
                            "CCol",
                            { attrs: { col: "6" } },
                            [
                              _c(
                                "CButton",
                                { attrs: { block: "", color: "facebook" } },
                                [_vm._v(" Facebook ")]
                              )
                            ],
                            1
                          ),
                          _c(
                            "CCol",
                            { attrs: { col: "6" } },
                            [
                              _c(
                                "CButton",
                                { attrs: { block: "", color: "twitter" } },
                                [_vm._v(" Twitter ")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }