<template>
  <CContainer class="min-vh-100 d-flex align-items-center">
    <CRow class="w-100 justify-content-center">
      <CCol md="6" sm="8">
        <CCard class="mx-4 mb-0">
          <CCardBody class="p-4">
            <CForm>
              <h1>Register</h1>
              <p class="text-muted">Create your account</p>
              <CInput
                placeholder="Username"
                type="text"
                autocomplete="username"
                v-model="username"
              >
                <template #prepend-content><CIcon name="cil-user"/></template>
              </CInput>
              <CInput
                placeholder="Email"
                type="text"
                v-model="email"
              >
              </CInput>
              <!-- <CInput
                placeholder="Email"
                autocomplete="email"
                prepend="@"
              /> -->
              <CInput
                placeholder="Password"
                type="password"
                autocomplete="new-password"
                v-model="password"
              >
                <!-- <template #prepend-content><CIcon name="cil-lock-locked"/></template> -->
              </CInput>
<!--               <CInput
                placeholder="Repeat password"
                type="password"
                autocomplete="new-password"
                class="mb-4"
              >
                <template #prepend-content><CIcon name="cil-lock-locked"/></template>
              </CInput> -->
              <CButton @click="signUp" color="success" block>Create Account</CButton>
            </CForm>
          </CCardBody>
          <CCardFooter class="p-4">
            <CRow>
              <CCol col="6">
                <CButton block color="facebook">
                  Facebook
                </CButton>
              </CCol>
              <CCol col="6">
                <CButton block color="twitter">
                  Twitter
                </CButton>
              </CCol>
            </CRow>
          </CCardFooter>
        </CCard>
      </CCol>
    </CRow>
  </CContainer>
</template>


<script>
import firebase from '@firebase/app';

export default {
  name: 'Signup',
  data () {
    return {
      username: '',
      email: '',
      password: ''
    }
  },
  methods: {
    signUp: function () {
      firebase.auth().createUserWithEmailAndPassword(this.email, this.password)
        .then(function(result) {
          // 仮処理. TODO 登録処理ごとfunctionsに移す
          const account = {
            id: result.user.uid,
            name: this.username,
            role: 1,
            registered: firebase.firestore.FieldValue.serverTimestamp(),
            company_name: '',
            postal_code: '',
            prefectures: '',
            address: '',
          }
          firebase.firestore().collection('users').doc(account.id).set(account).then(function() {
            this.$router.push({path: '/'})
          }.bind(this))
        }.bind(this))
        .catch(error => {
          alert(error.message)
        })
    }
  }
}
</script>